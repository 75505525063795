import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import MenuLight from "@assets/icons/menuLight.svg";
import MenuDark from "@assets/icons/menuDark.svg";
import FilterLight from "@assets/icons/filterLight.svg";
import FilterDark from "@assets/icons/filterDark.svg";
import SearchDark from "@assets/icons/searchDark.svg";
import SearchLight from "@assets/icons/searchLight.svg";
import { disableScroll } from "@utils/disableScroll";
import TechminisOrangeIcon from "@assets/icons/techminisOrange.svg";
import jobsIconDark from "@assets/icons/jobsIconDark.svg";
import jobsIconLight from "@assets/icons/jobsIconLight.svg";
import TechminisImg from "@assets/icons/techminis.svg";
import firestoreService from "@firestoreServices/firestoreServices";
import http from "@apiServices/http";
import urls from "@apiServices/apiUrls";
import { useTheme } from "next-themes";
import * as clickhouse from "./ch-events";
import ThemedImage from "@components/ui-components/ThemedImage";
// import cx from "classnames";
// import s from "./feedNavigationBar.module.scss";

const MenuDrawer = dynamic(() => import("@components/templates/MenuDrawer"));

const CustomizeYourFeed = dynamic(() => import("../CustomizeYourFeed"));

type NavigationBarProps = {
  handleOnBackFromCustomizeYourFeed?: (
    _followingPreference: Array<any>,
    _suggestedPreference: Array<any>,
    _updatedSavedKeywords: Array<any>
  ) => void;
  followingPreferences: Array<any>;
  suggestedPreferences: Array<any>;
  headerPosition: string;
  savedKeywords?: Array<any>;
  handleOnSignup?: () => void;
  onLogoClick: () => void;
  stopPolling: Function;
};

const FeedNavigationBar = ({
  handleOnBackFromCustomizeYourFeed,
  followingPreferences,
  savedKeywords: _savedKeywordsProps,
  suggestedPreferences,
  onLogoClick,
  headerPosition,
  handleOnSignup,
  stopPolling
}: NavigationBarProps) => {
  const router = useRouter();
  const { query } = useRouter();
  const { id } = query;
  const [menuClicked, setMenuClicked] = useState(false);
  const [customizeYourIconClicked, setCustomizeYourIconClickedClicked] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [followingTag, setFollowingTag] = useState(followingPreferences);
  const [suggestedTag, setSuggestedTag] = useState(suggestedPreferences);
  const [savedKeywords, setSavedKeywords] = useState(
    _savedKeywordsProps?.map((item) => ({
      label: item,
      isSelected: true
    }))
  );
  const [openCustomizedFeed, setOpenCustomizedFeed] = useState(false);
  const { resolvedTheme } = useTheme();

  const isNaukriPage =  router.pathname.split("/").includes("naukri");
  
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setFollowingTag(followingPreferences);
  }, [followingPreferences]);

  useEffect(() => {
    setSuggestedTag(suggestedPreferences);
  }, [suggestedPreferences]);

  useEffect(() => {
    setSavedKeywords(
      _savedKeywordsProps?.map((item) => ({
        label: item,
        isSelected: true
      }))
    );
  }, [_savedKeywordsProps]);

  const getUserFirestoreData = async () => {
    const firestore = new firestoreService();
    const userFirestoreData = await firestore.getUserData();
    return userFirestoreData;
  };

  const getPreferenceDetail = async () => {
    const userData = await getUserFirestoreData();

    http
      .get(urls.suggestions)
      .then((data: any) => {
        const followingTagTemp = [];
        let savedKeywordTemp = [];

        data.map((item, index) => {
          const size = 5;
          const tagTemp = [];
          if (index === 0) {
            item.isSelected = true;
          }

          item.tags.map((tag) => {
            tag["isSelected"] = false;
            tag["channelName"] = item.channelName;
            // tag["type"] = "preference";
          });

          item.tags.filter((el) => {
            if (!userData?.preferences?.includes(el.id)) {
              tagTemp.push(el);
            } else {
              el.isSelected = true;
              followingTagTemp.push(el);
            }
          });

          setFollowingTag(followingTagTemp);
          savedKeywords.filter((el) => {
            if (userData?.savedKeywords?.includes(el.label)) {
              savedKeywordTemp.push(el);
              return el;
            }
          });
          if (userData?.savedKeywords?.length > 0) {
            savedKeywordTemp = userData?.savedKeywords.map((el) => {
              const obj = {
                label: el,
                isSelected: true
              };
              return obj;
              // savedKeywordTemp.push(obj);
            });

            setSavedKeywords(savedKeywordTemp);
          }

          const res = tagTemp.reduce((acc, curr, i) => {
            if (!(i % size)) {
              // if index is 0 or can be divided by the `size`...
              acc.push(tagTemp.slice(i, i + size)); // ..push a chunk of the original array to the accumulator
            }
            return acc;
          }, []);
          item["tagDetail"] = res;
        });
        const dataTemp = [];
        data = data.map((el) => {
          if (el.tagDetail.length > 0) {
            dataTemp.push(el);
          }
        });

        setSuggestedTag(dataTemp);
        if (openCustomizedFeed) {
          handleOnBackFromCustomizeYourFeed(followingTagTemp, dataTemp, savedKeywordTemp);
        }
      })
      .catch(() => {});
  };

  const changeDrawerState = (isDrawerOpen: boolean, setIsDrawerOpen: Function) => () => {
    setIsDrawerOpen(!isDrawerOpen);
    setMenuClicked(true);
    disableScroll(!isDrawerOpen);
    clickhouse.trackCTAs({
      articleId: id,
      element_name: "hamburger_icon",
      page_section: headerPosition === "sticky" ? "sticky_header" : "floating_header"
    });
  };

  const handleOnBack = (
    _currentFollowingPreferences,
    _currentSuggestedPreferences,
    _currentSaveKeywords
  ) => {
    localStorage.setItem("preferenceSelected", JSON.stringify(followingPreferences));
    // handleOnBackFromCustomizeYourFeed(
    //   _currentFollowingPreferences,
    //   _currentSuggestedPreferences,
    //   _currentSaveKeywords
    // );
    setOpenCustomizedFeed(!openCustomizedFeed);
    disableScroll(!openCustomizedFeed);
    // if (type !== "drawer") {
    //   clickhouse.trackCTAs({
    //     element_name: "customizeyourfeed_icon",
    //     page_section:
    //       headerPosition === "sticky" ? "sticky_header" : "floating_header",
    //   });
    // }

    // if (openCustomizedFeed) {
    getPreferenceDetail(); // fetching and updating following and suggested tags when user clicks back button
    // }

    // disableScroll(!openCustomizedFeed);
  };

  return (
    <>
      <div
        className={`align-center bg-N100 dark:bg-DN050 ${headerPosition} top-0 z-[300] flex w-full  justify-between px-4 py-4 shadow-sectionBottom shadow-[#E0E9FF] dark:shadow-[#273142]`}
      >
        <div className="align-center flex">
          <ThemedImage
            mounted={mounted}
            resolvedTheme={resolvedTheme}
            darkSrc={MenuDark}
            lightSrc={MenuLight}
            alt="menu"
            onClick={changeDrawerState(openMenu, setOpenMenu)}
            width={24}
            height={24}
          />

          <div className="align-center ml-6 flex flex-col gap-1" onClick={onLogoClick}>
            <ThemedImage
              mounted={mounted}
              resolvedTheme={resolvedTheme}
              darkSrc={TechminisImg}
              lightSrc={TechminisOrangeIcon}
              alt="techminis"
              width="108"
              height="18"
            />
            <p className="ml-1 text-readingTextSmall font-regular leading-readingTextSmall tracking-wide text-N500 dark:text-N100">
              A <span className="font-bold">naukri.com</span> initiative
            </p>
          </div>
        </div>
        <div className="relative flex items-center">
          {/* <div className="absolute -top-2 -left-8 rounded bg-[#F63345]">
            <div className={cx(s.arrowBottom, "relative")}>
              <p className="px-1 py-0.5 text-s font-regular leading-s tracking-wide text-N100">
                New
              </p>
            </div>
          </div> */}
          {!isNaukriPage && (
            <div className="flex items-center relative">
              <a 
                title="Bigshyft Software Jobs" 
                href={`${process.env.NEXT_PUBLIC_JOBSEEKER_BASE_URL}/software-jobs?utm_source=techminis&utm_medium=header_link`}
                target="_blank" 
                rel="noreferrer"
              >
                <div className="relative">
                  <ThemedImage
                    mounted={mounted}
                    resolvedTheme={resolvedTheme}
                    darkSrc={jobsIconDark}
                    lightSrc={jobsIconLight}
                    alt="Bigshyft Software Jobs Link"
                    className="mr-5 h-[26px] w-[26px]"
                    onClick={() => {
                      clickhouse.trackCTAs({
                        element_name: "header_jobs_icon",
                        page_section:
                          headerPosition === "sticky" ? "sticky_header" : "floating_header"
                      });
                    }}
                    width={26}
                    height={26}
                  />
                  <div className="absolute right-2.5 -top-1">
                    <div className="relative">
                      <span className="block h-3.5 w-3.5 rounded-full bg-red opacity-20"/>
                      <span className="relative -top-[11px] left-[3px] block h-2 w-2 rounded bg-red"/>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          )}
          <ThemedImage
            mounted={mounted}
            resolvedTheme={resolvedTheme}
            darkSrc={FilterDark}
            lightSrc={FilterLight}
            alt="menu"
            onClick={() => {
              clickhouse.trackCTAs({
                articleId: id,
                element_name: "customizeyourfeed_icon",
                page_section: headerPosition === "sticky" ? "sticky_header" : "floating_header"
              });
              stopPolling();
              getPreferenceDetail();
              setCustomizeYourIconClickedClicked(true);
              setOpenCustomizedFeed(!openCustomizedFeed);
              disableScroll(!openCustomizedFeed);
            }}
            width={24}
            height={24}
          />
          <div className="align-center ml-5 flex">
            <ThemedImage
              mounted={mounted}
              resolvedTheme={resolvedTheme}
              darkSrc={SearchDark}
              lightSrc={SearchLight}
              alt="search"
              width={24}
              height={24}
              onClick={() => {
                clickhouse.trackCTAs({
                  articleId: id,
                  element_name: "search_icon",
                  page_section: headerPosition === "sticky" ? "sticky_header" : "floating_header"
                });

                // if (pathname.split("/").includes("naukri")) {
                // router.push(`/search`);
                // } else {
                router.push(`/search`);
                // }
              }}
            />
          </div>
        </div>
      </div>
      {mounted && (
        <>
          {menuClicked && (
            <MenuDrawer
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              handleCustomizeYourFeedMenu={() => {
                setCustomizeYourIconClickedClicked(true);
                stopPolling();
                getPreferenceDetail();
                setOpenCustomizedFeed(!openCustomizedFeed);
                disableScroll(!openCustomizedFeed);
              }}
              handleOnSignup={handleOnSignup}
            />
          )}
          {customizeYourIconClicked && (
            <CustomizeYourFeed
              open={openCustomizedFeed}
              // tab={
              //   followingTag.length > 0 || !!savedKeywords?.length
              //     ? "default"
              //     : "suggested"
              // }
              suggestedPreferences={suggestedTag}
              // setSuggestedPreferences={(detail) => setSuggestedTag(detail)}
              followingPreferences={followingTag}
              savedKeywords={savedKeywords}
              // setFollowingPreferences={(detail) => setFollowingTag(detail)}
              // setSavedKeywords={setSavedKeywords}
              onClose={() => {
                setOpenCustomizedFeed(!openCustomizedFeed);
                disableScroll(!openCustomizedFeed);
              }}
              handleOnBack={(
                currentFollowingPreferences,
                currentSuggestedPreferences,
                currentSaveKeywords
              ) =>
                handleOnBack(
                  currentFollowingPreferences,
                  currentSuggestedPreferences,
                  currentSaveKeywords
                )
              }
              title="Customize your feed"
            />
          )}
        </>
      )}
    </>
  );
};

export default FeedNavigationBar;
